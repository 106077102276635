import { useState } from 'react'
import { read, utils } from 'xlsx'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

import {
  ChargesAccount,
  IData,
  IRawData,
  KBankFundsTransferProductType,
} from './types'
import {
  generateKBankAccountFundsTransferTextFile,
  generateOtherBankFundsTransferTextFile,
} from './utils'

const KBANK_BANK_CODE = '004'

export const Form = () => {
  const [data, setData] = useState<IRawData[]>([])
  const [effectiveDate, setEffectiveDate] = useState(new Date())
  const [debitAccountNumber, setDebitAccountNumber] = useState('')
  const [chargesAccount, setChargesAccount] = useState(ChargesAccount.No)

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (file) {
      const reader = new FileReader()

      reader.onload = (event) => {
        const data = event.target?.result
        const workbook = read(data, { type: 'binary' })
        const firstSheetName = workbook.SheetNames[0]
        const worksheet = workbook.Sheets[firstSheetName]
        const excelData = utils.sheet_to_json(worksheet)
        setData(excelData as IRawData[])
      }

      reader.readAsArrayBuffer(file)
    }
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault()
    if (data && data.length > 0) {
      const dataList = transformRawData(data)
      const kbankData = dataList.filter(
        (data) => data.bankCode === KBANK_BANK_CODE,
      )
      const otherBankData = dataList.filter(
        (data) => data.bankCode !== KBANK_BANK_CODE,
      )

      if (kbankData.length > 0) {
        const { content, fileName } = generateKBankAccountFundsTransferTextFile(
          effectiveDate,
          kbankData,
          debitAccountNumber,
          chargesAccount,
        )
        downloadFile(content, fileName)
      }

      if (otherBankData.length > 0) {
        const { content, fileName } = generateOtherBankFundsTransferTextFile(
          effectiveDate,
          otherBankData,
          debitAccountNumber,
          chargesAccount,
          KBankFundsTransferProductType.IPP,
        )
        downloadFile(content, fileName)
      }
    }
  }

  return (
    <div className='container mx-auto px-3'>
      <form onSubmit={handleSubmit} className='mb-4'>
        <h1 className='text-3xl font-bold mb-4 mt-6'>อัปโหลด excel</h1>
        <div className='mb-2'>
          <input type='file' accept='.xlsx' onChange={handleFileChange} />
        </div>
        <div className='mb-2'>
          <label>กรอกเลขบัญชี : </label>
          <input
            type='text'
            onChange={(e) => setDebitAccountNumber(e.target.value)}
            value={debitAccountNumber}
            className='border border-black rounded px-1'
          />
        </div>
        <div className='mb-2'>
          <span>เลือกวันจ่ายเงิน : </span>
          <DatePicker
            selected={effectiveDate}
            onChange={(date) => setEffectiveDate(date || new Date())}
            className='border border-black rounded px-1'
          />
        </div>
        <div className='mb-2'>
          <input
            type='checkbox'
            id='charges_acc'
            onChange={(e) => {
              setChargesAccount(
                e.target.checked ? ChargesAccount.Yes : ChargesAccount.No,
              )
            }}
          />
          <label htmlFor='charges_acc'> จ่ายค่าธรรมเนียมโดยคนรับ</label>
        </div>

        <button
          type='submit'
          className='bg-gray-100 px-4 py-1 rounded-lg hover:bg-gray-200 border border-gray-300'
        >
          gen text file
        </button>
      </form>
    </div>
  )
}

function downloadFile(content: number[], fileName: string) {
  const encodedContent = new Uint8Array(content)
  const fileText = new Blob([encodedContent], { type: 'text/plain' })
  const element = document.createElement('a')
  element.href = URL.createObjectURL(fileText)
  element.download = fileName
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

function transformRawData(rawDataList: IRawData[]): IData[] {
  const dataList = rawDataList.map((rawData) => ({
    vat: rawData.vat * rawData['raw cost'],
    taxPercent: rawData.wht * 100,
    amount: rawData['raw cost'],
    netAmount: rawData['Amount (สุทธิ)'],
    bankAccountId: `${rawData.bankaccountid}`,
    bankCode: rawData['Bank Code'],
    bankAccountName: rawData.bankaccountname,
    bankBranch: `${rawData.branchcode}`,
    email: rawData.email,
    mobileNumber: rawData.phonenumber,

    taxId: `${rawData['เลขบัตรประจำตัว']}`,
    incomeTypeCode: '021',
  }))

  return dataList
}
