export interface IData {
  vat: number
  taxPercent: number
  amount: number
  netAmount: number
  bankAccountId: string
  bankCode: string
  bankAccountName: string
  bankBranch: string
  email: string
  mobileNumber: string
  taxId: string
  incomeTypeCode: string
}

export interface IRawData {
  vat: number
  'raw cost': number
  wht: number
  'Amount (สุทธิ)': number
  bankaccountid: number
  'Bank Code': string
  bankaccountname: string
  branchcode: number
  email: string
  phonenumber: string
  เลขบัตรประจำตัว: number | string
}

export enum TaxType {
  None = 'ไม่คิด',
  Fee = '40(2)',
  Other = '40(8)',
}

export enum KBankFundsTransferProductType {
  MCL = 'MCL',
  IPP = 'IPP',
  DCT = 'DCT',
}

export enum ChargesAccount {
  Yes = 'Y',
  No = 'N',
}
